.pdfReader button {

  padding: 12px;
  margin: 1px;
  background-color: rgb(33, 43, 54);
  border-radius: 3px;
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;

  /* &:hover {
    background-color: #7173ee;
  } */

}

.fileContainer {
  display: none;
}

.pageInfo {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}