.hideVideo {
  display: none;
  z-index: 999;
}

/* .isMe {
  position: absolute;
  width: 250px;
  height: 150px;
  left: 1180px;
  top: 676px;

  background: url('./user.png');
  border-radius: 10px;
} */

.is_me_video {
  border-radius: 10px;
  object-fit: cover;
  transform: scaleX(-1)
}

.video-container {
  position: relative;
}

.meHover .pin {
  margin-top: -16px !important;
  margin-left: -36px !important;
}

.share .pin {
  margin-top: -20px !important;
  margin-left: -20px !important;
}

.video-container .pin {
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
  margin-top: -20px;
  margin-left: -44px;
  transition: all ease .3s;
  -webkit-transition: all ease .3s;
  -moz-transition: all ease .3s;
}

.video-container:hover .pin {
  opacity: 1;
}

.video-container .css-1cao43e-MuiButtonBase-root-MuiIconButton-root {
  background-color: rgba(0, 0, 0, 0.3) !important;
}

.video-container .css-1cao43e-MuiButtonBase-root-MuiIconButton-root:hover {
  background-color: rgba(0, 0, 0, 0.7) !important;
}

.video-container .css-1cao43e-MuiButtonBase-root-MuiIconButton-root svg {
  opacity: .5;
}

.video-container .css-1cao43e-MuiButtonBase-root-MuiIconButton-root:hover svg {
  opacity: 1;
}

/* Maintain main container css for painlist and chat */
.mainContainer {
  padding: 0% !important;
  position: relative;
}

/* Maintain drower positon scroll in panilist */
.css-140axzu-MuiDrawer-docked .MuiDrawer-paper {
  overflow-y: hidden;
}

/* Change border radius of input box mui */
.inputRounded .MuiOutlinedInput-root {
  /* border-radius: 10px !important; */
}

/* Change height of search input in webinar list page */
.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
  height: 3.5ch !important;
}

/* show audio animation */
/* Voice Detector */
.voice-detector {
  width: 24px;
  height: 24px;
  background: #1976D2;
  border-radius: 50px;
  /* position: absolute; */
  right: 10px;
  bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all ease .3s;
  -webkit-transition: all ease .3s;
  -moz-transition: all ease .3s;
  -o-transition: all ease .3s;
}

.voice-detector .dot-one,
.voice-detector .dot-two,
.voice-detector .dot-three {
  width: 4px;
  height: 4px;
  background: #ffffff;
  margin: 1px;
  border-radius: 50px;
  transition: all ease .3s;
  -webkit-transition: all ease .2s;
  -moz-transition: all ease .2s;
  -o-transition: all ease .2s;
}

/* Level 2 */
.voice-detector.level-2 .dot-one {
  height: 6px;
}

.voice-detector.level-2 .dot-three {
  height: 6px;
}

/* Level 3 */
.voice-detector.level-3 .dot-one {
  height: 6px;
}

.voice-detector.level-3 .dot-two {
  height: 10px;
}

.voice-detector.level-3 .dot-three {
  height: 6px;
}

/* Level 4 */
.voice-detector.level-4 .dot-one {
  height: 8px;
}

.voice-detector.level-4 .dot-two {
  height: 12px;
}

.voice-detector.level-4 .dot-three {
  height: 8px;
}

/* Level 5 */
.voice-detector.level-5 .dot-one {
  height: 12px;
}

.voice-detector.level-5 .dot-two {
  height: 14px;
}

.voice-detector.level-5 .dot-three {
  height: 12px;
}

/* Level 6 */
.voice-detector.level-6 .dot-one {
  height: 12px;
}

.voice-detector.level-6 .dot-two {
  height: 14px;
}

.voice-detector.level-6 .dot-three {
  height: 12px;
}

/* Level 7 */
.voice-detector.level-7 .dot-one {
  height: 12px;
}

.voice-detector.level-7 .dot-two {
  height: 14px;
}

.voice-detector.level-7 .dot-three {
  height: 12px;
}

/* Level 8 */
.voice-detector.level-8 .dot-one {
  height: 12px;
}

.voice-detector.level-8 .dot-two {
  height: 14px;
}

.voice-detector.level-8 .dot-three {
  height: 12px;
}

/* Level 9 */
.voice-detector.level-9 .dot-one {
  height: 12px;
}

.voice-detector.level-9 .dot-two {
  height: 14px;
}

.voice-detector.level-9 .dot-three {
  height: 12px;
}

/* Level 10 */
.voice-detector.level-10 .dot-one {
  height: 12px;
}

.voice-detector.level-10 .dot-two {
  height: 14px;
}

.voice-detector.level-10 .dot-three {
  height: 12px;
}

/* Chat Notification Dot */
.image-indicators {
  right: 20px;
  position: absolute;
  top: 10px;
}

.image-indicators label {
  display: flex;
  position: absolute;
  cursor: pointer;
  transition-property: opacity;
  transition-duration: 300ms;
  transition: opacity 0.3s ease-out .3s;
  z-index: 1;
}

.image-indicators>label>span {
  background-color: #2196f3;
  width: 10px;
  height: 10px;
  border-radius: 60px;
  transform: scale(1);
}

.image-indicators>label>span>span {
  display: inline-block;
  vertical-align: 20px;
  background-color: rgba(33, 150, 243, 1);
  width: 10px;
  height: 10px;
  transform: scale(0);
  border-radius: 60px;
  animation: scaleGrow 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  animation-play-state: running;
}

@keyframes scaleGrow {
  from {
    transform: scale(0);
    background-color: rgba(33, 150, 243, 1);
  }

  to {
    transform: scale(3);
    opacity: 0;
  }
}

@keyframes scaleDecreases {
  from {
    transform: scale(1);
  }

  50% {
    transform: scale(0.9);
  }

  to {
    transform: scale(1);
  }
}

@-webkit-keyframes type2 {
  0% {
    width: 0;
  }

  1% {
    opacity: 1;
  }

  100% {
    opacity: 1;
    width: 100%;
    border: none;
  }
}

/* Sideabr Drag (not in use now)*/
.drag.right,
.drag.left {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

.drag.top,
.drag.bottom {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
}

/* total count and info icon css */
.floating-buttons {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  column-gap: 4px;
  align-items: center;
}

/* css for show invite email in box at schedule webinar */
.tag-item-schedule {
  background-color: #d4d5d6;
  display: inline-block;
  font-size: 14px;
  border-radius: 30px;
  height: 30px;
  padding: 0 4px 0 1rem;
  display: inline-flex;
  align-items: center;
  margin: 0 0.3rem 0.3rem 0;
}

.tag-item-schedule>.button {
  background-color: white;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  font: inherit;
  margin-left: 10px;
  font-weight: bold;
  padding: 0;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* invite dialog webinar main screen */
.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  background-color: #222222 !important;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  color: #fff !important;
}

/* mobile view css */

.mobileView {
  max-width: 589px;
  margin: auto;
  height: 100%;
  background: #fff;
}

.ScrollBlock {
  height: 100%;
  display: block;
  margin: 0px;
  padding: 0px;
}

.ContentBlock {
  /* height: 100%; */
  position: relative;
  overflow: auto;
}

.MenuBar {
  position: fixed;
  bottom: 0px;
  /* max-width: calc(589px - 28px); */
  /* width: calc(100% - 28px); */
  width: 100%;
  /* background: #fff; */
  -webkit-box-shadow: 0px -10px 30px 0px rgba(96, 112, 135, 0.5);
  -moz-box-shadow: 0px -10px 30px 0px rgba(96, 112, 135, 0.5);
  box-shadow: 0px -10px 30px 0px rgba(96, 112, 135, 0.5);
}

.MeetingMenuBar {
  position: fixed;
  bottom: 0px;
  /* max-width: calc(589px - 28px); */
  /* width: calc(100% - 28px); */
  width: 100%;
  background: #000000;
}

.mobilePeerViewVideo {
  /* border-radius: 10px; */
  object-fit: cover;
}

/* @media screen and (max-width: 480px) {
  
} */

.checkMark {
  position: relative;
}

.checkMark:before {
  position: absolute;
  content: "";
  background: url(../../../public/images/check-cercle.svg) no-repeat center center;
  background-size: 20px 20px;
  width: 20px;
  height: 20px;
  right: 16px;
  top: 50%;
  transform: translateY(-10px);
}

#waitingRoom {
  background: url(../../../public/images/waitingRoomBg.png) no-repeat center center;
  background-size: cover;
}

.react-pdf__Page__canvas {
  /* margin: 0 auto;
  width: 50px !important;
  height: 100px !important; */

}

.custom-pdf-1 {
  /* width: 100px !important; */
}

.rs-stack-item {
  color: #000000;
}